import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import {
  formatRfcTimeStringToTimeSlotView,
  formatShortDate,
} from '../../../../utils/dateAndTime/dateAndTime';
import { Optional } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';
import { BOOKINGS_APP_DEF_ID } from '@wix/bookings-catalog-calendar-viewer-utils';

export enum DialogState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

type BaseDialogViewModel<type, Data=""> = {
  isOpen: boolean;
  state: DialogState;
  data: Data;
  type: Type;
};

export type GeneralDialogViewModel = BaseDialogViewModel<
  Omit<dialogtype, 'ServicesPreferences'="">,
  {
    titleText?: string;
    contentText: string;
    confirmButtonText: string;
    confirmButtonUrlLink?: string;
    closeButtonText?: string;
  }
>;

export type ServicesPreferencesDialogViewModel = BaseDialogViewModel<
  DialogType.ServicesPreferences,
  Required<calendarstate>['servicesPreferencesModalData']
>;

export type DialogViewModel =
  | GeneralDialogViewModel
  | ServicesPreferencesDialogViewModel;

// eslint-disable-next-line no-shadow
export enum DialogType {
  RescheduleConfirm = 'reschedule-confirm',
  JoinWaitlist = 'join-waitlist',
  PremiumViewer = 'premium-viewer',
  PremiumPreview = 'premium-preview',
  ServicesPreferences = 'services-preferences',
}

export const MOBILE_APP_INVITE_LENDING_PAGE_URL_PREFIX =
  'https://apps.wix.com/place-invites/join-lp/';

export const memoizedDialogViewModel: MemoizedViewModalFactory<dialogviewmodel> =
  {
    dependencies: {
      state: ['dialog', 'selectedTime', 'servicesPreferencesModalData'],
    },
    createViewModel: createDialogViewModel,
  };

export function createDialogViewModel({
  state,
  context,
}: ViewModelFactoryParams<calendarstate, CalendarContext="">): DialogViewModel {
  const { dialog } = state;

  if (!dialog) {
    return {
      isOpen: false,
      state: DialogState.IDLE,
      data: { titleText: '', contentText: '', confirmButtonText: '' },
      type: DialogType.JoinWaitlist,
    };
  }

  const { type } = dialog;

  if (type === DialogType.ServicesPreferences) {
    return {
      data: state.servicesPreferencesModalData!,
      isOpen: true,
      state: DialogState.IDLE,
      type,
    };
  }

  const confirmButtonUrlLink = getUrl(dialog?.type, context);

  return {
    isOpen: true,
    state: dialog?.state || DialogState.IDLE,
    data: {
      ...getTexts({ state, context }),
      ...(confirmButtonUrlLink ? { confirmButtonUrlLink } : {}),
    },
    type,
  };
}

function getTexts({
  state,
  context,
}: ViewModelFactoryParams<calendarstate, CalendarContext="">) {
  const { dialog, selectedTime } = state;
  const { t, languageSettings } = context;

  switch (dialog?.type) {
    case DialogType.RescheduleConfirm:
      return {
        titleText: t('app.rescheduled-booking.dialog.title'),
        contentText: t('app.rescheduled-booking.dialog.content', {
          date: formatShortDate(
            selectedTime!,
            languageSettings!.dateRegionalSettingsLocale,
          ),
          time: formatRfcTimeStringToTimeSlotView(
            selectedTime!,
            languageSettings!.dateRegionalSettingsLocale,
          ),
        }),
        confirmButtonText: t('app.rescheduled-booking.dialog.button'),
      };
    case DialogType.JoinWaitlist:
      return {
        titleText: t('app.dialog.join-waitlist.title'),
        contentText: t('app.dialog.join-waitlist.content'),
        confirmButtonText: t('app.dialog.join-waitlist.button'),
        closeButtonText: t('app.dialog.join-waitlist.close-button'),
      };
    case DialogType.PremiumViewer:
      return {
        contentText: t('app.dialog.premium-viewer.content'),
        confirmButtonText: t('app.dialog.premium-viewer.button'),
      };
    case DialogType.PremiumPreview:
      return {
        titleText: t('app.dialog.premium-preview.title'),
        contentText: t('app.dialog.premium-preview.content'),
        confirmButtonText: t('app.dialog.premium-preview.confirm-button'),
      };
    default:
      return {
        titleText: '',
        contentText: '',
        confirmButtonText: '',
      };
  }
}

const getUrl = (dialogType: Optional<dialogtype>, context: CalendarContext) => {
  const {
    flowAPI: {
      environment: { isEditorX },
      controllerConfig: {
        appParams: { instanceId },
      },
    },
  } = context;

  if (dialogType === DialogType.PremiumPreview) {
    const referral = `bookings_previewsite_bookflowpopup`;
    const baseUrl = isEditorX
      ? 'https://manage.editorx.com/wix/api'
      : 'https://premium.wix.com/wix/api';

    return `${baseUrl}/premiumStart?appDefId=${BOOKINGS_APP_DEF_ID}&instanceId=${instanceId}&referralAdditionalInfo=${referral}`;
  }

  const metaSiteId =
    context.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId;

  return dialogType === DialogType.JoinWaitlist
    ? MOBILE_APP_INVITE_LENDING_PAGE_URL_PREFIX + metaSiteId
    : '';
};
</dialogtype></calendarstate,></calendarstate,></dialogviewmodel></calendarstate></dialogtype,></type,>